// import { colors } from "@mui/material";

export default {
    styleOverrides: {
        root: {
            borderRadius: 3,
            overflow: 'hidden',
        },
        // colorPrimary: {
        //     backgroundColor: colors.blueGrey[50],
        // },
    }
}
