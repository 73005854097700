export default {
    styleOverrides: {
        root: {
            backgroundColor: '#f1f3f4',
            color: '#5f6368',
            fontWeight: 400,
        },

    }
}
