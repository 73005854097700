export default {
    styleOverrides: {
        root: {
            // borderRadius: 16
            borderRadius: 8,
            boxShadow: 'none',
            border: '1px solid #dadce0',
        },
    }
}
