export default {
    styleOverrides: {
        root: {
            letterSpacing: '0',
        },
        gutterBottom: {
            marginBottom: 8,
        },
    }
}
